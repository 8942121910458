<template>
  <b-modal
    id="modal-scrollable_question"
    scrollable
    title="回答分类"
    :hide-footer="true"
  >
    <div class="accordion" role="tablist">
      <b-card
        no-body
        class="mb-1"
        v-for="cat in $store.state.quick_answers"
        :key="cat.id"
      >
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle="'accordion-' + cat.id"
            style="background: #2c3e50; border-color: #2c3e50"
            >{{ cat.name }}
            <span class="when-opened" style="float: right">
              <b-icon icon="chevron-up" />
            </span>
            <span class="when-closed" style="float: right">
              <b-icon icon="chevron-down" /> </span
          ></b-button>
        </b-card-header>
        <b-collapse
          v-bind:id="'accordion-' + cat.id"
          accordion="my-accordion"
          role="tabpanel"
        >
          <div
            v-for="i in cat.answers"
            :key="'ans' + i.id"
            style="padding: 2px 0px"
          >
            <b-list-group-item @click="getQuickAnswers(i.title)">
              <p style="margin: 0px">{{ i.title }}</p>
            </b-list-group-item>
          </div>
        </b-collapse>
      </b-card>

      <!-- <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-2
            style="background: #2c3e50; border-color: #2c3e50"
            >提现<span class="when-opened" style="float: right">
              <b-icon icon="chevron-up" />
            </span>
            <span class="when-closed" style="float: right">
              <b-icon icon="chevron-down" /> </span
          ></b-button>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>bbb</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-3
            style="background: #2c3e50; border-color: #2c3e50"
            >交易<span class="when-opened" style="float: right">
              <b-icon icon="chevron-up" />
            </span>
            <span class="when-closed" style="float: right">
              <b-icon icon="chevron-down" /> </span
          ></b-button>
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>ccc</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-4
            style="background: #2c3e50; border-color: #2c3e50"
            >Test<span class="when-opened" style="float: right">
              <b-icon icon="chevron-up" />
            </span>
            <span class="when-closed" style="float: right">
              <b-icon icon="chevron-down" /> </span
          ></b-button>
        </b-card-header>
        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>ccc</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-5
            style="background: #2c3e50; border-color: #2c3e50"
            >注册<span class="when-opened" style="float: right">
              <b-icon icon="chevron-up" />
            </span>
            <span class="when-closed" style="float: right">
              <b-icon icon="chevron-down" /> </span
          ></b-button>
        </b-card-header>
        <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>ccc</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card> -->
    </div>
  </b-modal>
</template>
      
<script>
export default {
  name: "QuestionList",
  props: ["parentContext"],
  data() {
    return {
      categories: [],
    };
  },
  methods: {
    loadCategories() {
      let that = this;
      that
        .$http({
          url: "api/answer/categoryList",
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            that.$store.commit("setQuickAnswerCategories", data.data);
            that.loadAnswers();
          }
        });
    },
    loadCategoryAnswers(catId) {
      let that = this;
      that
        .$http({
          url: "api/answer/list/" + catId,
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            let obj = {
              catId: catId,
              answers: data.data,
            };
            that.$store.commit("setAnswers", obj);
          }
        });
    },
    loadAnswers() {
      let that = this;
      that.$store.state.quick_answers.forEach((element) => {
        that.loadCategoryAnswers(element.id);
      });
    },
    getQuickAnswers(msg) {
      this.parentContext.inputMessage = msg;
      this.$bvModal.hide("modal-scrollable_question");
    },
  },
  mounted: function () {
    this.loadCategories();
  },
};
</script>
      
<style>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>