<template>
  <div>
    <b-modal id="modal-scoped_channelAddSearch" size="xl">
      <b-overlay
        :show="isShowLoader"
        no-wrap
        style="z-index: 20000"
      ></b-overlay>
      <template #modal-header>
        <h5>添加会员</h5>
      </template>

      <template #default>
        <b-row>
          <b-col cols="5">
            <b-form inline>
              <label class="mr-sm-4">搜索单个用户:</label>
              <b-form-input
                aria-label="First name"
                v-model="query"
              ></b-form-input>
            </b-form>
          </b-col>
          <b-col cols="6">
            <b-form inline>
              <label class="mr-sm-4">选择代理:</label>
              <b-form-select
                v-model="selectedAgent"
                :options="allAgentList"
                text-field="name"
                value-field="id"
                style="width: 50%"
              >
                <template #first>
                  <b-form-select-option :value="0"
                    >所有客服</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form>
          </b-col>
          <b-col cols="1">
            <b-button variant="primary" class="mb-2" @click="smartSearch">
              <b-icon icon="search" aria-label="Help"></b-icon>
            </b-button>
          </b-col>
        </b-row>
        <b-row
          ><b-col cols="12"
            ><b-form-checkbox v-model="selectAll" @change="selectAllMembers"
              >选择全部</b-form-checkbox
            ></b-col
          ></b-row
        >
        <b-modal v-model="selectAllModal" centered title="确认">
          <p class="my-4">您确认添加所有成员？</p>
          <template #modal-footer="{ cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button
              size="sm"
              @click="
                cancel();
                selectAll = false;
              "
            >
              取消
            </b-button>
            <b-button
              size="sm"
              variant="primary"
              @click="
                addUserToGroup('', 1, selectedAgent, query);
                selectAllModal = false;
                selectAll = false;
                addGroupMembersModal = false;
              "
            >
              确认
            </b-button>
          </template>
        </b-modal>
        <b-row style="height: 650px; overflow-y: auto; padding-top: 15px">
          <b-table-simple :current-page="currentPage" responsive>
            <b-tbody>
              <b-tr v-for="i in channelMemberList" :key="i.id">
                <b-td style="width: 23%; padding-left: 0px">
                  <b-avatar v-bind:src="i.avatar"></b-avatar
                ></b-td>
                <b-td style="width: 24%; line-height: 40px">{{
                  i.name === "" ? "佚名" : i.name
                }}</b-td>
                <b-td style="width: 24%; line-height: 40px">{{
                  i.account
                }}</b-td>
                <b-td style="width: 23%; line-height: 40px">{{
                  i.telephone
                }}</b-td>
                <b-td
                  style="text-right; padding-right: 25px; padding-top: 15px;"
                  ><b-form-checkbox
                    v-model="i.selected"
                    @change="onUserAddListCheckbox(i)"
                  ></b-form-checkbox
                ></b-td>
              </b-tr>
              <b-tr v-if="channelMemberList.length == 0"
                ><b-td>
                  <p style="text-align: center; margin: 30px">无结果</p></b-td
                ></b-tr
              >
              <b-overlay
                :show="isShowLoaderPagination"
                no-wrap
                style="z-index: 20000"
              ></b-overlay>
            </b-tbody>
          </b-table-simple>
          <b-pagination
            v-if="total_rows > 1"
            style="position: absolute; bottom: -60px; width: 200px"
            v-model="currentPage"
            :total-rows="total_rows"
            aria-controls="member_table"
            @change="pageChangeSmartSearch"
          ></b-pagination>
        </b-row>
      </template>

      <template #modal-footer="{ cancel, ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="cancel()"> 取消 </b-button>
        <b-button size="sm" variant="primary" @click="ok()"> 确认 </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ChannelSearchAddUser",
  props: ["selectedUser"],
  data() {
    return {
      selectedAgent: 0,
      isShowLoaderPagination: false,
      isShowLoader: false,
      selectAllUsers: false,
      allAgentList: [],
      channelMemberList: [],
      selectAll: false,
      query: "",
      selectAllModal: false,
      currentPage: 1,
      total_rows: 0,
      showMyUser: 0,
    };
  },
  methods: {
    // ======================= add group member modal functionality - get agent list===========

    loadAllAgents() {
      let that = this;
      that
        .$http({
          url: "/api/user/agentList",
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.selectAllUsers = false;
            that.allAgentList = res.data.data;
            that.allAgentList.forEach((i) => {
              that.channelMemberList.push(i);
            });
          }
        });
    },

    loadChannelTeam() {
      let that = this;
      that
        .$http({
          url: "/api/user/myTeam",
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          if (res.data.code == 200) {
            res.data.data.forEach((i) => {
              that.channelMemberList.push(i);
            });
          }
        });
    },
    smartSearch() {
      let that = this;
      this.isShowLoaderPagination = true;
      this.isShowLoader = true;
      that
        .$http({
          url:
            "api/user/smartSearch?groupId=" +
            that.selectedUser.id +
            "&agentId=" +
            that.selectedAgent +
            "&showMyUsers=" +
            that.showMyUser +
            "&currentPage=" +
            (that.currentPage - 1) +
            "&keyWord=" +
            that.query,
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            // this.addGroupMembersModal = true;
            // this.modalShowAddAgent = false;
            this.isShowLoaderPagination = false;
            this.isShowLoader = false;
            that.channelMemberList = data.dataList;
            that.total_rows = res.data.page.count;
            // console.log(data.dataList);
          } else {
            this.isShowLoaderPagination = false;
            this.isShowLoader = false;
          }
        })
        .finally(() => {
          this.isShowLoaderPagination = false;
          this.isShowLoader = false;
        });
    },
    pageChangeSmartSearch(page) {
      this.currentPage = page;
      this.channelMemberList = [];
      this.smartSearch();
    },
    addUserToGroup(account, allSelected = 0, agentId = 0, keyWord = "") {
      let that = this;
      that
        .$http({
          url: "/api/group/member/add/" + this.selectedUser.id,
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {
            members: account,
            allSelected: allSelected,
            agentId: agentId,
            keyWord: keyWord,
            type: 1,
          },
        })
        .then((res) => {
          let data = res.data;
          // if (data.code == 200) {
          //   this.$bvModal.msgBoxOk("成功添加", {
          //     title: "成功",
          //     size: "sm",
          //     buttonSize: "sm",
          // okVariant: "primary",
          // okTitle: '确认',
          //     headerClass: "p-2 border-bottom-0",
          //     footerClass: "p-2 border-top-0",
          //     centered: true,
          //   });
          // }
        });
    },
    selectAllMembers() {
      this.selectAllModal = true;
    },
    onUserAddListCheckbox(item) {
      if (item.selected === undefined) {
        item.selected = true;
      }
      console.log(item.selected);
      if (item.selected) {
        //add user
        console.log("add user");
        this.addUserToGroup(item.account);
      } else {
        //remove user
        this.removeUserToGroup(item.account);
      }
    },
  },
  mounted: function () {
    this.loadAllAgents();
    this.loadChannelTeam();
  },
  activated: function () {
    console.log("activated");
  },
};
</script>
<style scoped>
</style>