<template>
  <div>
    <b-sidebar
      id="sidebar-footer_searchChannel"
      aria-label="Sidebar with search users"
      shadow
      backdrop
      width="17%"
    >
      <template #header>
        <b-input-group size="sm" class="mb-2">
          <b-form-input
            type="search"
            placeholder="搜索群聊"
            v-model="queryChannel"
            debounce="500"
          ></b-form-input>
          <!-- <b-input-group-prepend is-text v-b-toggle.sidebar-footer_search>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend> -->
        </b-input-group>
      </template>
      <template #footer="{ hide }">
        <div
          class="d-flex text-light align-items-center px-3 py-2"
          style="background: #2c3e50"
        >
          <b-button size="sm" @click="hide">取消</b-button>
        </div>
      </template>
      <div class="px-3 py-2">
        <div>
          <b-row
            style="padding: 6px 0px"
            v-for="(item, index) in resultsChannel"
            :key="index"
            @click="onResult(item)"
          >
            <b-col col lg="3" style="padding-right: 0px">
              <b-avatar :src="item.avatar"></b-avatar>
              <!-- <b-avatar
                badge
                badge-top
                badge-variant="success"
                :src="item.avatar"
              ></b-avatar> -->
            </b-col>

            <b-col col lg="9">
              <p class="searchUserName" style="padding-top: 10px">
                {{ item.name }}
              </p>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import eventBus from "../assets/js/eventBus";
export default {
  name: "ChannelSearch",
  data() {
    return {
      queryChannel: "",
      resultsChannel: [],
    };
  },
  watch: {
    queryChannel: function (val) {
      this.searchChannel(val);
    },
  },
  methods: {
    searchChannel(queryChannel) {
      let that = this;
      that
        .$http({
          url: "api/group/search/" + queryChannel + "?type=1",
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          var data = res.data;
          if (data.code == 200) {
            that.resultsChannel = res.data.dataList;
          }
        });
    },
    onResult(item) {
      eventBus.$emit("onSelectedChannel", item);
    },
  },
  mounted: function () {
    let that = this;
    eventBus.$on("onShowSearchPopChannel", (data) => {
      that.queryChannel = "";
      that.resultsChannel = [];
    });
    this.queryChannel = "";
  },
};
</script>
<style scoped>
.searchUserName {
  text-align: left;
  padding-top: 17px;
  margin: 0;
  color: #2c3e50;
}
</style>
