import Vue from 'vue'
import Vuex from 'vuex'
import ChatWindow from '../components/ChatWindow'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        login_account: null,
        token: null,
        message_list: [],
        channel_list: [],
        system_messages: [],
        system_unread: 0,
        quick_answers: [],
        message_threshold: 6 * 24 * 60 * 60 * 1000,
        member_cache: [],
    },
    actions: {
    },
    mutations: {
        initialiseStore(state) {
            // Check if the ID exists
            if (localStorage.getItem('store')) {
                this.replaceState(
                    Object.assign(state, JSON.parse(localStorage.getItem('store')))
                );
            }
        },
        setLoginAccount(state, account) {
            state.login_account = account;
        },
        setToken(state, token) {
            state.token = token;
        },
        clearToken(state) {
            state.token = null;
        },
        setMessage(state, dataObj) {
            let data = dataObj.message;
            console.log(data);

            if (data.action === "888") {
                dataObj.caller.logout();
                return;
            }
            //handle channel message
            if (data.action === "1" || data.action === "3") {
                let channel = state.channel_list.find(o => o.id === parseInt(dataObj.sentMessage ? data.receiver : data.sender));
                if (!channel) {
                    if (!dataObj.sentMessage) {
                        data.read = false;
                        //show notice
                        dataObj.caller.makeToast(data, channel);
                        dataObj.caller.playSound();
                    }
                    let item = { id: parseInt(data.sender), unread: dataObj.sentMessage ? 0 : 1, timestamp: data.timestamp, messages: [data], user: { id: data.sender, username: "TODO" } };
                    state.channel_list.push(item);
                    dataObj.caller.getChannelInfo(data.sender);
                } else {
                    if (channel.messages.find(m => m.id == data.id)) {
                        return;
                    }
                    if (!dataObj.sentMessage) {
                        data.read = false;
                        channel.unread++;
                        //show notice
                        dataObj.caller.makeToast(data, channel, channel.user.username);
                        dataObj.caller.playSound();
                    }
                    channel.messages.push(data);
                    channel.timestamp = data.timestamp
                }
                //sort list according to timestamp
                state.channel_list = state.channel_list.slice().sort((a, b) => b.timestamp - a.timestamp);
                return;
            }

            if (data.action === '0' || data.action === '2') {
                if (data.sender === "system") {
                    dataObj.caller.playSound();
                    data.read = false;
                    state.system_messages.push(data);
                    state.system_unread++;
                    return;
                }
                let account = state.message_list.find(o => o.id === (dataObj.sentMessage ? data.receiver : data.sender));
                if (!account) {
                    if (!dataObj.sentMessage) {
                        data.read = false;
                        //show notice
                        dataObj.caller.makeToast(data, account);
                        dataObj.caller.playSound();
                    }
                    let item = { id: data.sender, unread: dataObj.sentMessage ? 0 : 1, timestamp: data.timestamp, messages: [data], user: { account: data.sender } };
                    state.message_list.push(item);
                    dataObj.caller.getUserInfo(data.sender);
                } else {
                    if (account.phone === "") {
                        dataObj.caller.getUserInfo(data.sender);
                    }
                    if (account.messages.find(m => m.id == data.id)) {
                        return;
                    }
                    if (!dataObj.sentMessage) {
                        data.read = false;
                        account.unread++;
                        //show notice
                        dataObj.caller.makeToast(data, account, account.user.username);
                        dataObj.caller.playSound();
                    }
                    account.messages.push(data);
                    account.timestamp = data.timestamp
                }
                //sort list according to timestamp
                state.message_list = state.message_list.slice().sort((a, b) =>
                    (b.timestamp > 0 ? b.timestamp : 0) - (a.timestamp > 0 ? a.timestamp : 0)
                );
            }

        },
        readAllUnread(state, data) {
            let account = state.message_list.find(o => o.id === data.account);
            if (account) {
                account.messages.forEach(message => {
                    if (!message.read) {
                        message.read = true;
                        data.context.readRequest(message.id, data.account);
                        account.unread > 0 ? account.unread-- : account.unread = 0;
                    }
                });
            }
        },
        readAllUnreadChannel(state, data) {
            let account = state.channel_list.find(o => o.id == data.account);
            if (account) {
                account.messages.forEach(message => {
                    if (!message.read) {
                        message.read = true;
                        data.context.readRequest(message.id, data.account);
                        account.unread > 0 ? account.unread-- : account.unread = 0;
                    }
                });
            }
        },
        clearMessageCache(state) {
            state.message_list = [];
            state.member_cache = [];
            state.channel_list = [];
        },
        updateMessage(state, data) {
            if (data.message.action === "1" || data.message.action === "3") {
                let account = state.channel_list.find(o => o.id === data.message.receiver);
                let message = account.messages.find(o => o.id === data.message.oldId);
                if (message) {
                    account.messages.splice(account.messages.indexOf(message), 1);
                    account.messages.push(data.message);
                    // message = data.message;
                    // message.sendfail = false;
                    // console.log(message);
                } else {
                    console.log('cannot find message');
                }
            } else {
                let account = state.message_list.find(o => o.id === data.message.receiver);
                let message = account.messages.find(o => o.id === data.message.oldId);
                if (message) {
                    account.messages.splice(account.messages.indexOf(message), 1);
                    account.messages.push(data.message);
                    // message = data.message;
                    // message.sendfail = false;
                    // console.log(message);
                } else {
                    console.log('cannot find message');
                }
            }
        },
        updateMessageItem(state, data) {
            let account = state.message_list.find(o => o.id === data.id);
            if (account) {
                account = data;
            }
        },
        updateUser(state, data) {
            data.id = data.account;
            data.username = data.user_name;
            let account = state.message_list.find(o => o.id === data.id);
            account.user = data;
        },
        setQuickAnswerCategories(state, categories) {
            state.quick_answers = [];
            categories.forEach(element => {
                element.answers = [];
                state.quick_answers.push(element);
            });
        },
        setAnswers(state, answers) {
            let category = answers.catId;
            let ans = answers.answers;

            let categoryObj = state.quick_answers.find(o => o.id == category);
            if (categoryObj) {
                categoryObj.answers = ans;
            }
        },
        //1. remove entire user if the last message older than threshold
        //2. search message list and remove messages older than threashold
        //TODO better to improve this method
        clearOlderMessages(state) {
            let nowTimestamp = new Date();
            let oldestTimestamp = nowTimestamp - state.message_threshold;

            state.message_list.forEach(account => {
                if (account.timestamp < oldestTimestamp && account.unread == 0) {
                    state.message_list.splice(state.message_list.indexOf(account))
                } else {
                    account.messages.forEach(message => {
                        if (message.timestamp < oldestTimestamp && message.read == true) {
                            account.messages.splice(account.messages.indexOf(message), 1)
                        }
                    });
                }

                //check sending sate messages
                account.messages.forEach(message => {
                    if (message.sending) {
                        message.sending=false;
                        message.sendfail=true;
                    }
                });
            });

            state.channel_list.forEach(account => {
                if (account.timestamp < oldestTimestamp && account.unread == 0) {
                    state.message_list.splice(state.message_list.indexOf(account))
                } else {
                    account.messages.forEach(message => {
                        if (message.timestamp < oldestTimestamp && message.read == true) {
                            account.messages.splice(account.messages.indexOf(message), 1)
                        }
                    });
                }
            });

        },
        /**
         * get the online and agent user list from agent
         * and update the user list
         * @param {*} state 
         * @param {*} data 
         */
        updateUserList(state, data) {
            data.forEach(user => {
                let account = state.message_list.find(o => o.id === user.account);
                if (!account) {
                    user.username = user.name+" "+user.account;
                    user.id = user.account;
                    let item = { id: user.account, unread: 0, timestamp: null, messages: [], user: user };
                    state.message_list.push(item);
                }
            });
        },
        /**
         * get channel list from api and update store list
         * @param {*} state 
         * @param {*} data 
         */
        updateChannelList(state, data) {
            data.forEach(element => {
                let channel = state.channel_list.find(o => o.id === element.id);
                if (!channel) {
                    element.username = element.name;
                    let item = { id: parseInt(element.id), unread: 0, timestamp: null, messages: [], user: element };
                    state.channel_list.push(item);
                }
            });
        },
        /**
         * update single channel
         * @param {*} state 
         * @param {*} data 
         */
        updateChannel(state, data) {
            let channel = state.channel_list.find(o => o.id === data.id);
            if (channel) {
                data.username = data.name;
                channel.user = data;
            }
        },
        addMessageToSystem(state, data) {
            state.system_messages = [];
            data.forEach(message => {
                switch (message.action) {
                    case "108":
                        break;
                    case "888":
                        break;

                    default:
                        message.read = true;
                        state.system_messages.push(message);
                        break;
                }
            });
        },
        readSystemMessages(state) {
            state.system_unread = 0;
        },
        addMemberCache(state, data) {
            if (!state.member_cache.find(o => o.id === data.id)) {
                state.member_cache.push(data);
            }

            var memberLength = state.member_cache.length;
            if (memberLength > 1000) {
                state.member_cache.splice(0, 500);
            }
        },
    }
})
store.subscribe((mutation, state) => {
    // Store the state object as a JSON string
    localStorage.setItem('store', JSON.stringify(state));
});

//Comparer Function    
function GetSortOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}

export default store;