<template>
  <div>
    <b-modal id="modal-scoped_channelAddRemove" size="xl">
      <b-overlay
        :show="isShowLoader"
        no-wrap
        style="z-index: 20000"
      ></b-overlay>
      <template #modal-header>
        <h5>群组成员名单</h5>
      </template>

      <template #default>
        <b-row>
          <b-col cols="6">
            <b-form inline>
              <label class="mr-sm-4">搜索用户:</label>
              <b-form-input aria-label="user" v-model="query"></b-form-input>
              <b-button variant="primary" style="margin-left: 10px">
                <b-icon
                  icon="search"
                  @click="
                    currentPage = 1;
                    showMembersList();
                  "
                  aria-label="username"
                ></b-icon>
              </b-button>
            </b-form>
          </b-col>
          <b-col cols="6" style="text-align: right">
            <div style="padding-right: 15px">
              <b-button variant="primary" v-b-modal.modalRemoveAllC
                >移除所有</b-button
              >
            </div>
          </b-col>
        </b-row>
        <b-modal id="modalRemoveAllC" centered title="确认">
          <p>您确认移除所有成员？</p>
          <template #modal-footer="{ cancel }">
            <b-button size="sm" @click="cancel()"> 取消 </b-button>
            <b-button
              size="sm"
              variant="primary"
              @click="removeAllChannelMembers()"
            >
              确认
            </b-button>
          </template>
        </b-modal>
        <b-row><b-col cols="12"></b-col></b-row>
        <b-row style="height: 700px; overflow-y: auto; padding-top: 15px">
          <b-table-simple :current-page="currentPage" responsive>
            <b-tbody>
              <b-tr v-for="i in allCmembers" :key="i.id">
                <b-td style="width: 23%; padding-left: 0px">
                  <b-avatar v-bind:src="i.avatar"></b-avatar
                ></b-td>
                <b-td style="width: 24%; line-height: 40px">{{
                  i.name === "" ? "佚名" : i.name
                }}</b-td>
                <b-td style="width: 24%; line-height: 40px">{{
                  i.account
                }}</b-td>
                <b-td style="width: 23%; line-height: 40px"
                  >1{{ i.telephone }}</b-td
                >
                <b-td
                  style="text-right; padding-right: 25px; padding-top: 15px;"
                  ><b-form-checkbox
                    v-model="i.isChecked"
                    @change="removeUser(i)"
                  ></b-form-checkbox
                ></b-td>
              </b-tr>
              <b-overlay
                :show="isShowLoaderPagination"
                no-wrap
                style="z-index: 20000"
              ></b-overlay>
            </b-tbody>
          </b-table-simple>
          <b-pagination
            v-if="total_rows > 1"
            style="position: absolute; bottom: -60px; width: 200px"
            v-model="currentPage"
            :total-rows="total_rows"
            aria-controls="member_table"
            @change="pageChange"
          ></b-pagination>
        </b-row>
      </template>

      <template #modal-footer="{ cancel, ok }">
        <b-button size="sm" @click="cancel()"> 取消 </b-button>
        <b-button size="sm" variant="primary" @click="ok()"> 确认 </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ChannelAddRemoveUser",
  props: ["selectedUser"],
  data() {
    return {
      allCmembers: "",
      currentPage: 1,
      total_rows: 0,
      showMyUser: false,
      query: "",
      isShowLoaderPagination: false,
      isShowLoader: false,
    };
  },
  methods: {
    showMembersList() {
      let that = this;
      this.isShowLoaderPagination = true;
      this.isShowLoader = true;
      that
        .$http({
          url:
            "/api/group/member/list/" +
            that.selectedUser.id +
            "/" +
            (that.currentPage - 1),
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {
            type: 1,
            keyword: that.query,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.isShowLoaderPagination = false;
            this.isShowLoader= false;
            that.allCmembers = res.data.dataList;
            that.allCmembers.forEach((element) => {
              element.isChecked = true;
            });
            that.total_rows = res.data.page.count;
          } else {
            this.isShowLoaderPagination = false;
            this.isShowLoader = false;
          }
        })
        .finally(() => {
          this.isShowLoader = false;
          this.isShowLoaderPagination = false;
        });
    },
    pageChange(page) {
      this.currentPage = page;
      this.allCmembers = [];
      this.showMembersList();
    },
    removeUser(item) {
      let that = this;
      console.log(item, that.selectedUser);

      that
        .$http({
          url: "/api/group/member/removeNew/" + this.selectedUser.id,
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {
            member: item.account,
            type: 1,
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            //TODO add success and error messages
          }
        });
    },
    // Remove all channel members
    removeAllChannelMembers() {
      let that = this;
      that
        .$http({
          url:
            "/api/group/member/removeAll/" + this.selectedUser.id + "?type=1",
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            that.showMembersList();
            this.$bvModal.msgBoxOk("成功移除", {
              title: "成功",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
            this.$bvModal.hide("modalRemoveAllC");
          }
        });
    },
  },
  mounted: function () {},
};
</script>
<style scoped>
</style>